.about-us-page {
  width: 100%;
  margin: 40px 0 0;
  padding: 0 110px;

  min-height: 410px;

  background: url(../../img/background-element/about-us-main__pattern.svg) top 204px right 103px no-repeat;
  box-sizing: border-box;
}

.about-us-page__title {
  /* width: min-content; */
  color: #282A30;
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.21;
}

.about-us-page__pharagraph {
  margin: 25px 0 30px;
  padding: 0;
  color: rgba(40, 42, 48, 0.90);
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.41;
  /* 47.08px */
}

.about-us-page__list {
  margin: 0;
  padding: 0 10px 0;
  list-style: none;
  /* align-items: center; */
}

.about-us-page__item {
  margin: 0;
  padding: 0 10px 0;

  border-left: 2px solid rgba(40, 42, 48, .3);
  box-sizing: border-box;
}

.about-us-page__pharagraph_type_center {
  text-align: center;
}

.about-us-page__background-rectangle {
  position: absolute;
  top: 24px;
  left: 0;
  width: 35px;
  height: 264px;

  background-color: #F7515C;

  transition: 0.3s all;
}

@media screen and (max-width: 1280px) {
  .about-us-page {
    padding: 0 98px;
  }
}

@media screen and (max-width: 1024px) {

  .about-us-page {
    padding: 0 78px;
  }
}

@media screen and (max-width: 834px) {
  .about-us-page {
    padding: 0 11px;
  }

  .about-us-page__background-rectangle {
    left: -30px;
  }
}

@media screen and (max-width: 768px) {
  .about-us-page {}

  .about-us-page__title {
    font-size: 45px;
  }

  .about-us-page__ptharagraph {
    font-size: 20px;
  }

}

@media screen and (max-width: 430px) {
  .about-us-page {}

}

@media screen and (max-width: 320px) {
  .about-us-page {}

}
.gallery-apart-item {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gallery-apart-item__button {
  width: 100px;
  height: 75px;
  margin: 0;
  padding: 2px;
  border: 2px solid transparent;

  outline: none;
  background-color: transparent;
}

.gallery-apart-item__button_type_active {
  border: 2px solid #F7515C;
}

.gallery-apart-item__button:hover {
  cursor: pointer;
  border: 2px solid skyblue;
}

.gallery-apart-item__image {
  /* padding: 2px; */
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* background-color: gray; */
}
.footer {
  margin: 40px 0 0;
  padding: 0 110px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  box-sizing: border-box;
}

.footer__navigation {
  margin: 0;
  padding: 100px 0 45px;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  border-top: 1px solid #282A30;
}

.footer__wrapper {
  margin: 0;
  padding: 0;

  max-width: 215px;

  list-style: none;
}


.footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__item {
  margin: 0 0 27px;
  padding: 0;

}

.footer__item:first-child {
  margin: 0 0 51px;
}

.footer__item:last-child {
  margin: 0;
}

.footer__title {
  margin: 0;
  padding: 0;



  color: #282A30;
  font-family: Nunito;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 1px;

  text-decoration: none;
}


.footer__link {
  position: relative;
  margin: 0;
  padding: 0;
  color: rgba(40, 42, 48, 0.80);


  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.80;

  letter-spacing: 1px;
  text-decoration: none;

  transition: all .3s;
}

.footer__link:hover {
  color: #282A30;
}

.footer__link::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.footer__link:hover::after {
  transform: scaleX(1);
}

.footer__item_type_container {
  /* width: 160px; */
  display: flex;

  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
}

.footer__link_type_icon {
  margin: 0;
  padding: 0;
  display: block;
  width: 30px;
  height: 30px;
  /* background-color: aqua; */
  border-radius: 10px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  opacity: .7;
  transition: 0.3s all;
}

.footer__link_type_icon::after {
  display: none;
}

.footer__link_type_icon:hover {
  opacity: 1;

}

.footer__link_type_whatsapp {
  background-image: url(../../img/background-element/icon__whatsapp.svg);
}

.footer__link_type_telegram {
  background-image: url(../../img/background-element/icon__telegram.svg);
}

.footer__link_type_instagram {
  background-image: url(../../img/background-element/icon__instagram.svg);
}

.footer__link_type_vkontakte {
  background-image: url(../../img/background-element/icon__vkontakte.svg);
}


@media screen and (max-width: 1280px) {
  .footer {
    padding: 0 98px;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 0 78px;
  }
}

@media screen and (max-width: 950px) {
  .footer {
    padding: 0 107px;
  }

  .footer__navigation {
    padding: 50px 0 45px;

    max-width: 570px;
    row-gap: 25px;
    column-gap: 100px;
  }

  .footer__list {
    width: 215px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0 98px;
  }

  .footer__navigation {
    row-gap: 56px;
    column-gap: 50px;
  }

  .footer__item:first-child {
    margin: 0 0 25px;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    padding: 0 64px;
  }

  .footer__navigation {
    padding: 40px 0 35px;
  }
}

@media screen and (max-width: 320px) {
  .footer {
    padding: 0 35px;
  }
}
.main {
  position: relative;

  width: 100%;
  max-width: 1440px;
  margin: 0;
  padding: 0;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* overflow: hidden; */
}
.slider-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  max-width: 1220px;
  min-height: 500px;

  /* background-color: rgba(255, 255, 0, 0.4); */
  box-sizing: border-box;

}


@media screen and (max-width: 1280px) {
  .slider-main {}
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 834px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 480px) {
  .slider-main__scroll {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
}


@media screen and (max-width: 320px) {}
.slide-more-apart {
  width: 200px;

  margin: 0;
  padding: 0;
  /* padding: 10px; */
}

.slide-more-apart__link {
  text-decoration: none;
}

.slide-more-apart__image-wrapper {
  width: 200px;
  height: 150px;
}

.slide-more-apart__image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.slide-more-apart__subtitle {
  width: 90%;
  margin: 5px 0 0;
  padding: 0;
  color: #06a7fe;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.22;
}

.slide-more-apart__paragraph {
  margin: 5px 0 0;
  padding: 0;
  color: #282A30;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.22;
}

.slide-more-apart__paragraph_type_accent {
  margin: 5px 0 0;
  padding: 0;
  color: rgba(40, 42, 48, 0.7);
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.22;
}
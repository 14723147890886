.apartment {
  margin: 40px 0 0;
  width: 100%;
  /* background-color: rgba(94, 43, 189, 0.1); */

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.apartment__title {
  margin: 0 0 44px 0;
  padding: 0 110px 0;

  width: 100%;
  box-sizing: border-box;
  color: #282A30;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.22;
}

.apartment__space {
  margin: 0;
  padding: 0;
  word-spacing: 10px;
}

.apartment__accent {
  color: #fff;
  font-family: Montserrat;
  font-size: 100px;
  font-weight: 700;
  line-height: 1.22;
  text-shadow:
    -0 -1px 1px #000000,
    0 -1px 1px #000000,
    -0 1px 1px #000000,
    0 1px 1px #000000,
    -1px -0 1px #000000,
    1px -0 1px #000000,
    -1px 0 1px #000000,
    1px 0 1px #000000,
    -1px -1px 1px #000000,
    1px -1px 1px #000000,
    -1px 1px 1px #000000,
    1px 1px 1px #000000,
    -1px -1px 1px #000000,
    1px -1px 1px #000000,
    -1px 1px 1px #000000,
    1px 1px 1px #000000;
}

@media screen and (max-width: 1280px) {

  .apartment__title {
    padding: 0px 97px 0;
  }
}

@media screen and (max-width: 1090px) {
  .apartment {
    margin: 45px 0 0;
  }

  .apartment__title {
    margin: 0 0 88px 0;
    padding: 0px 78px 0;
  }

  .apartment__accent {
    font-size: 64px;
    line-height: 1.21;
    text-shadow:
      -0 -1px 1px #000000,
      0 -1px 1px #000000,
      -0 1px 1px #000000,
      0 1px 1px #000000,
      -1px -0 1px #000000,
      1px -0 1px #000000,
      -1px 0 1px #000000,
      1px 0 1px #000000,
      -1px -1px 1px #000000,
      1px -1px 1px #000000,
      -1px 1px 1px #000000,
      1px 1px 1px #000000,
      -1px -1px 1px #000000,
      1px -1px 1px #000000,
      -1px 1px 1px #000000,
      1px 1px 1px #000000;
  }
}

@media screen and (max-width: 834px) {
  .apartment {
    .apartment__title {
      padding: 0px 11px 0;
    }
  }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 500px) {
  .apartment__title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.22;
  }

  .apartment__accent {
    font-size: 48px;
    line-height: 1.62;
  }

}


@media screen and (max-width: 480px) {}

@media screen and (max-width: 480px) {

  .apartment__title {
    font-size: 32px;
    line-height: 1.53;
  }

  .apartment__accent {
    font-size: 40px;
    line-height: 1.95;
  }
}
.contacts {
  width: 100%;
  margin: 40px 0 0;
  padding: 0 110px;

  min-height: 410px;

  background: url(../../img/background-element/about-us-main__pattern.svg) top 204px right 103px no-repeat;
  box-sizing: border-box;
}

.contacts__title {
  width: min-content;
  color: #282A30;
  font-family: Montserrat;
  font-size: 55px;
  font-weight: 700;
  line-height: 1.21;
}

.contacts__accent {
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.39;
  color: #FFFFFF;
  text-shadow:
    -0 -1px 1px #000000,
    0 -1px 1px #000000,
    -0 1px 1px #000000,
    0 1px 1px #000000,
    -1px -0 1px #000000,
    1px -0 1px #000000,
    -1px 0 1px #000000,
    1px 0 1px #000000,
    -1px -1px 1px #000000,
    1px -1px 1px #000000,
    -1px 1px 1px #000000,
    1px 1px 1px #000000,
    -1px -1px 1px #000000,
    1px -1px 1px #000000,
    -1px 1px 1px #000000,
    1px 1px 1px #000000;
  /* 139.063% */
}

.contacts__pharagraph {
  margin: 25px 0 30px;
  padding: 0;
  color: rgba(40, 42, 48, 0.90);
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.41;
  /* 47.08px */
}

.contacts__pharagraph_type_item {
  margin: 0;
  padding: 0;
}

.contacts__list {
  margin: 0;
  padding: 0 10px 0;
  list-style: none;
}

.contacts__item {
  margin: 0 0 25px 0;
  padding: 0 10px 0;

  border-left: 2px solid rgba(40, 42, 48, .3);
  box-sizing: border-box;
}

.contacts__link {
  position: relative;
  display: inline;
  color: #282A30;
  font-family: Nunito;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;

  letter-spacing: 1px;
  text-decoration: none;
  transition: all .3s;
}

.contacts__link:hover {
  color: #282A30;
}

.contacts__link::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.contacts__link:hover::after {
  transform: scaleX(1);
}


.contacts__pharagraph_type_center {
  text-align: center;
}

.contacts__background-rectangle {
  position: absolute;
  top: 24px;
  left: 0;
  width: 35px;
  height: 264px;

  background-color: #F7515C;

  transition: 0.3s all;
}

@media screen and (max-width: 1280px) {
  .contacts {
    padding: 0 98px;
  }
}

@media screen and (max-width: 1024px) {

  .contacts {
    padding: 0 78px;
  }
}

@media screen and (max-width: 834px) {
  .contacts {
    padding: 0 11px;
  }

  .contacts__background-rectangle {
    left: -30px;
  }
}

@media screen and (max-width: 768px) {
  .contacts {}

  .contacts__title {
    font-size: 45px;
  }

  .contacts__accent {
    font-size: 45px;
    letter-spacing: 2px;
  }


}

@media screen and (max-width: 430px) {
  .contacts {}

  .contacts__title {
    font-size: 39px;
  }

  .contacts__accent {
    font-size: 33px;
  }

  .contacts__pharagraph {
    font-size: 20px;
  }

  .contacts__link {
    font-size: 20px;
  }
}

@media screen and (max-width: 320px) {
  .contacts {}

}
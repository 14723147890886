@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./woff2/nunito-regular.woff2) format('woff2'),
    url(./woff/nunito-regular.woff) format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./woff2/nunito-semibold.woff2) format('woff2'),
    url(./woff/nunito-semibold.woff) format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./woff2/nunito-bold.woff2) format('woff2'),
    url(./woff/nunito-bold.woff) format('woff');
}
.recommenadion-place__place-info {
  margin: 0;
  padding: 15px 0 0;
  display: flex;
  gap: 10px;

  flex-direction: column;
  align-items: center;

  border-top: 1px solid #000;
}

.recommenadion-place__place-info-text {
  width: 210px;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.recommenadion-place__title {
  margin: 10px 0 0;
  margin: 0;
  padding: 0;
  color: #282A30;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.22;
  text-align: center;
}

/* 
.recommenadion-place__subtitle {
  width: 90%;
  margin: 5px 0 0;
  padding: 0;
  color: #06a7fe;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.22;
  text-align: center;
} */

.recommenadion-place__link {
  text-decoration: none;
}

.recommenadion-place__image-wrapper {
  width: 200px;
  height: 150px;
}

.recommenadion-place__image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.recommenadion-place__list {
  margin: 0 0 25px 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 25px;
}
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: 100vh;
}

.page-not-found__title {
  margin: 0;
  padding: 185px 0 0;
  color: #000;
  text-align: center;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 140px;
  font-weight: 400;
  line-height: 1.2;
}

.page-not-found__subtitle {
  margin: 5px 0 0;
  padding: 0;
  color: #000;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page-not-found__link {
  margin: 184px 0 0;
  padding: 0;
  color: #3456F3;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;

  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .page-not-found {}

  .page-not-found__title {
    margin: 0;
  }

  .page-not-found__subtitle {}

  .page-not-found__link {}

}


@media screen and (max-width: 480px) {
  .page-not-found {
    position: relative;
  }

  .page-not-found__title {
    margin: 0;
    padding: 0;
    font-size: 80px;
    line-height: 1.21;
  }

  .page-not-found__subtitle {
    margin: 10px 0 0;
    font-size: 12px;
    line-height: 1.25;
  }


  .page-not-found__link {
    position: absolute;
    margin: 0 0 30px;
    bottom: 0;

    font-size: 12px;
    line-height: 1.25;
  }
}

@media screen and (max-height: 590px) and (min-width: 480px) {
  .page-not-found__title {
    padding: 15px 0 0;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./woff2/montserrat-regular.woff2) format('woff2'),
    url(./woff/montserrat-regular.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./woff2/montserrat-semibold.woff2) format('woff2'),
    url(./woff/montserrat-semibold.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./woff2/montserrat-bold.woff2) format('woff2'),
    url(./woff/montserrat-bold.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./woff2/montserrat-black.woff2) format('woff2'),
    url(./woff/montserrat-black.woff) format('woff');
}
.slide-main {
  margin: 0;
  padding: 0;

  max-width: 277px;


  display: flex;
  flex-direction: column;
  align-items: center;
}


.slide-main__link {
  position: relative;

  display: flex;

  color: #000;
  text-decoration: none;
}

.slide-main__btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.slide-main__button {
  margin-bottom: 15px;

  color: #282A30;
  font-family: Nunito Sans;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 1px;

  /* margin: 0; */
  padding: 10px 15px;

  border: 0;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .7);

  transition: all .3s;
}

.slide-main__button:hover {
  font-size: 22px;
  background-color: #fff;

}

.slide-main__image {
  width: 277px;
}

.slide-main__subtitle {
  margin: 44px 0 0;
  padding: 0;

  max-width: 220px;

  text-align: center;

  color: #282A30;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
}

.slide-main__price {
  margin: 17px 0 0;
  padding: 0;

  text-align: center;

  color: rgba(40, 42, 48, 0.30);
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 480px) {
  .slide-main__subtitle {
    margin: 10px 0 0;
  }

  .slide-main__price {
    margin: 10px 0 0;
  }
}

@media screen and (max-width: 320px) {}
.description {
  /* height: 100px; */

}

.description_type_active {
  height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.description__pharagraph {
  margin: 15px 0 10px;
  padding: 0;
  color: rgba(40, 42, 48, 1);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
}

.description b {
  font-weight: 700;
}

.description__list {
  margin: 35px 0 35px;
  padding: 0;
  list-style: none;
}

.description__item {
  margin: 5px 0 5px;
  padding: 0;
}

.description__item .description__pharagraph {
  margin: 0;
  padding: 0;
}
.navigation {
  margin: 6px 0 0 0;
}

.navigation__list {
  margin: 0;
  padding: 0;

  max-width: 645px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 75px;
  row-gap: 26px;
  flex-wrap: wrap;

  list-style: none;
}

.navigation__item {
  margin: 0;
  padding: 0;

  color: #282A30;
  font-family: Nunito Sans;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 1px;
}

.navigation__link {
  position: relative;

  display: block;
  color: #282A30;
  text-decoration: none;
}

.navigation__link:hover {
  color: #282A30;
}

.navigation__link::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 1px;
  bottom: -5px;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navigation__link:hover::after {
  transform: scaleX(1);
}

.navigation__link_type_active {
  text-decoration: underline;
  text-underline-offset: 10px;
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 1142px) {
  .navigation {
    padding: 0 0 0 22px;
    width: 100%;
  }

  .navigation__list {
    column-gap: normal;
    row-gap: normal;
    justify-content: space-between;

  }
}

@media screen and (max-width: 930px) {
  .navigation {
    padding: 0 0 0 41px;
    margin: 23px 0 0 0;
  }

  .navigation__item {
    font-size: 16px;
  }

  .navigation__link_type_active {
    text-underline-offset: 9px;
  }
}

@media screen and (max-width: 768px) {
  .navigation {
    padding: 0 0 0 23px;
    margin: 16px 0 0 0;
  }

  .navigation__list {
    column-gap: 5px;
    row-gap: 20px;
  }
}

@media screen and (max-width: 630px) {
  .navigation {
    display: none;
  }
}


@media screen and (max-width: 430px) {}

@media screen and (max-width: 320px) {}